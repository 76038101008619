import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import intervalPlural from "i18next-intervalplural-postprocessor";
import { TARGET_LANGUAGE } from "../config/i18n";
import targetTranslation from "./assets/translations/target.json";

// import Backend from "i18next-xhr-backend";

const resources = {
  /* eslint-disable */
  [TARGET_LANGUAGE]: targetTranslation
};


i18next
  // .use(Backend)
  .use(initReactI18next)
  .use(intervalPlural)
  .init({
    resources,
    // fallbackLng,
    fallbackLng: TARGET_LANGUAGE,
    debug: false,
    whitelist: [TARGET_LANGUAGE],

    react: {
      // wait: false,
      useSuspense: true,
    },
  });

export default i18next;
